import { Button, PillButton } from "@components/Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { ClientList } from "@components/ClientList"
import { Layout } from "@components/Layout"
import { Arrow } from "@components/UI"
import React from "react"
/*global gtag*/
import { SalesForm } from "@components/Form"
import { Seo } from "@components/Seo"
import { usePageSetup } from "@components/usePageSetup"
import BrandingMessagingIcon from "@svg/electrical-distributors/branding-and-messaging.svg"
import { splitText } from "@utils"
import { graphql } from "gatsby"
import { StaticPost } from "../components/Blog/StaticPost"

import CommunicationsIcon from "@svg/electrical-distributors/communications.svg"
import StrategyMarketingIcon from "@svg/electrical-distributors/strategy-marketing.svg"

const ElectricalDistributorsPage = ({ data }) => {
  const { heroImage, meetJKImage, omniChannelImage, posts } = data
  const { themeColor } = usePageSetup({
    themeColor: "bg-gray-darkest",
    navigationColor: "text-white",
    displayGeneralForm: false,
  })

  const isBrowser = typeof window !== "undefined"

  const contactSubmission = () => {
    if (!isBrowser) {
      return
    }

    gtag("event", "contact_us_form_submission", {
      event_category: "contact_us",
      event_label: "electrical-distributors",
    })
  }

  return (
    <Layout>
      <Seo title="Electrical Distributors" description="" />
      <div className="overflow-hidden">
        <div className={themeColor} style={{ height: "94px" }}></div>
        <div className={themeColor}>
          <div className="container relative">
            <div className="absolute inset-x-0 bottom-0">
              <div className="aspect-w-326 aspect-h-93 sm:aspect-w-667 lg:aspect-w-1436 sm:aspect-h-78 extend before:bg-white-dark after:bg-white-dark"></div>
            </div>

            <div className="relative sg-hero-grid">
              <div className="mb-8 sg-title pt-14">
                <div>
                  <h1
                    className="mt-12 text-clamp-30-35"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-white":
                          "Customers today want more than a distributor—they want a personalized experience from a partner they trust.",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="pb-15 sm:pb-40 sg-message" data-aos="fade-up">
                <p className="text-white text-clamp-16-18">
                  <span className="font-bold text-teal-medium">
                    Recent Salesforce research
                    <sup>
                      <a
                        href="https://www.salesforce.com/resources/articles/customer-expectations/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        1
                      </a>
                    </sup>{" "}
                    found that 84% of business buyers expect sales reps to act as trusted advisors—but 73% say most
                    sales interactions feel transactional.
                  </span>{" "}
                  In a crowded market, electrical distributors have an opportunity to stand out with a personalized
                  customer experience that delivers on both quality and partnership.
                </p>
                <p className="text-white text-clamp-16-18">
                  At JK, we understand your audiences’ unique needs and create the customized branding and marketing
                  strategies that help you connect with them. From national chains to independent distributors, we help
                  build trust, drive engagement, and ignite growth for businesses like yours.
                </p>
                <div>
                  <Button className="mt-8 text-purple-light" link="#electrical-distributors-form">
                    Get in touch with an expert now
                  </Button>
                </div>
              </div>
              <div className="flex lg:transform lg:translate-y-16 sg-image">
                <div className="grid self-end w-full h-full grid-cols-8 sm:grid-cols-12">
                  <div className="relative flex items-center col-span-7 sm:col-span-12 lg:col-span-11 lg:col-start-2">
                    <div className="pb-6 sm:pl-5 lg:pl-0 lg:pr-5 sm:pb-0">
                      <div className="relative" data-aos="fade-left">
                        <GatsbyImage objectFit="contain" image={getImage(heroImage)} alt="Electrical Distributors" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main className="mt-8">
          <section>
            <div className="container">
              <div className="grid-cols-2 gap-8 lg:grid">
                <div>
                  <GatsbyImage objectFit="contain" image={getImage(omniChannelImage)} alt="Omnichannel" />
                </div>

                <div className="flex items-center justify-center mt-16 lg:mt-0">
                  <div>
                    <div className="grid-cols-6 xl:grid">
                      <div className="col-span-4 col-start-2">
                        <h2 className="font-bold text-clamp-25-35">
                          Omnichannel marketing is changing the game for distributors.
                        </h2>
                        <p className="mt-10 text-16">
                          Our recent blog post describes six ways electrical distributors can leverage omnichannel
                          marketing to stand out from the competition—and live up to evolving customer expectations.
                          Read it now for practical tips you can use to implement a successful omnichannel strategy.
                        </p>
                      </div>
                    </div>
                    <div className="grid-cols-6 xl:grid">
                      <div className="col-span-5 col-start-2">
                        <Button
                          className="mt-8 text-left text-purple"
                          link="/ideas-and-insights/hardwired-for-success-the-omnichannel-marketing-advantage-for-distributors"
                          external={true}
                        >
                          Hardwired for success: the omnichannel advantage for distributors.
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="mt-16 lg:mt-48 pb-clamp-22-40 bg-gray">
            <div className="container">
              <div className="flex flex-wrap lg:flex-row">
                <div className="w-full grid-cols-6 lg:grid lg:w-1/2">
                  <div data-aos="fade-right" className="col-span-5 py-clamp-22-40">
                    <h3 className="font-bold text-white text-clamp-30-45">Meet JK.</h3>
                    <p className="text-white text-clamp-18-22">
                      We’re a women-owned brand and communications agency with the unique ability to build stories for
                      all the audiences you need to reach. Companies count on us to help them discover their voice, cut
                      through the noise, and make an impact.
                    </p>
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <div data-aos="fade-up" className="relative lg:px-6 lg:-top-24">
                    <GatsbyImage objectFit="contain" image={getImage(meetJKImage)} alt="Meet JK" />
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-clamp-12-24 lg:mt-0">
                <div className="flex flex-col w-full grid-cols-3 gap-8 lg:pl-6 sm:w-10/12 lg:gap-4 lg:w-full lg:grid mt-clamp-9-12">
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up">
                    <div>
                      <BrandingMessagingIcon />
                    </div>
                    <div className="w-full lg:max-w-80">
                      <h3 className="font-bold text-20 text-teal-medium">Branding &amp; messaging</h3>
                      <p className="mt-4 text-white text-16">
                        From brand strategy and development to deployment and execution, we’ll work with you to craft
                        and tell your best brand story.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up" data-aos-delay={100}>
                    <div>
                      <StrategyMarketingIcon />
                    </div>
                    <div className="w-full lg:max-w-80">
                      <h3 className="font-bold text-20 text-teal-medium">Strategy &amp; marketing</h3>
                      <p className="mt-4 text-white text-16">
                        Build a strong foundation for marketing success with strategy and planning, social media and
                        integrated marketing campaigns, analytics, and website design.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up" data-aos-delay={200}>
                    <div>
                      <CommunicationsIcon />
                    </div>
                    <div className="w-full lg:max-w-86">
                      <h3 className="font-bold text-20 text-teal-medium">Communications &amp; engagement</h3>
                      <p className="mt-4 text-white text-16">
                        Inspire and engage your stakeholders and people—both inside and out.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

          <section className="pt-clamp-29-45">
            <div className="container">
              <h3 className="text-clamp-30-45">We’re always thinking about storytelling.</h3>
              <div className="relative grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mt-15">
                {posts?.edges?.[2] ? (
                  <StaticPost post={posts.edges[2].node}>
                    <Button
                      external={true}
                      link={`/ideas-and-insights/${posts.edges[2].node.slug}`}
                      className="text-white"
                    >
                      Learn more
                    </Button>
                  </StaticPost>
                ) : null}
                {posts?.edges?.[1] ? (
                  <StaticPost post={posts.edges[1].node}>
                    <Button
                      external={true}
                      link={`/ideas-and-insights/${posts.edges[1].node.slug}`}
                      className="text-white"
                    >
                      Learn more
                    </Button>
                  </StaticPost>
                ) : null}
                {posts?.edges?.[0] ? (
                  <StaticPost post={posts.edges[0].node}>
                    <Button
                      external={true}
                      link={`/ideas-and-insights/${posts.edges[0].node.slug}`}
                      className="text-white"
                    >
                      Learn more
                    </Button>
                  </StaticPost>
                ) : null}
              </div>
            </div>
          </section>

          <section className="pt-clamp-10-30 pb-clamp-16-30">
            <div className="container">
              <div>
                <div data-aos="fade">
                  <PillButton staticButton={true} className="bg-purple-lighter">
                    Our Clients
                  </PillButton>
                </div>
                <h2
                  className="mt-10 text-clamp-35-45"
                  data-aos="stagger"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-gray-darkest": "Stories we’ve shared.",
                    }),
                  }}
                />
                <ClientList
                  className="gap-x-6 lg:gap-x-20 mt-clamp-9-8"
                  clients={[
                    "ad",
                    "amazon",
                    "amerlux",
                    "bestbuy",
                    "cat",
                    "dupont",
                    "hammond",
                    "j-and-j",
                    "netflix",
                    "merck",
                    "pepsico",
                    "philips",
                    "priceline",
                    "signify",
                    "spectrum",
                    "unilog",
                    "wb",
                    "zumtobel",
                  ]}
                />
              </div>
            </div>
          </section>

          <div id="electrical-distributors-form" style={{ scrollMarginTop: "78px" }}></div>
          <section className="bg-purple-light pt-clamp-34-51 pb-clamp-27-40">
            <div className="container flex lg:justify-center">
              <div className="xl:w-10/12">
                <div className="grid-cols-10 lg:grid">
                  <div className="col-span-8">
                    <h2
                      data-aos="stagger"
                      className="text-title"
                      dangerouslySetInnerHTML={{
                        __html: splitText({
                          "text-gray-darkest": "Let’s showcase what",
                          "text-white": " sets you apart.",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="grid-cols-10 lg:grid mt-clamp-7-11">
                  <div className="col-span-8 xl:col-span-7">
                    <p data-aos="fade-up">Please fill out the form below and we’ll be in touch soon.</p>
                  </div>
                </div>
              </div>
            </div>
            <section className="container mt-24">
              <div className="mx-auto xl:w-10/12">
                <SalesForm
                  id="632"
                  subject="JK Design - Sustainability"
                  recipient="katiek@jkdesign.com, markm@jkdesign.com"
                  fieldColor="bg-teal-light"
                  fn={contactSubmission}
                />
              </div>
            </section>
          </section>
        </main>
      </div>
    </Layout>
  )
}

export default ElectricalDistributorsPage

export const ElectricalDistributorsPageQuery = graphql`
  query electricalDistributorsPageQuery {
    heroImage: file(relativePath: { eq: "next-gen-branding/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    provenProcessImage: file(relativePath: { eq: "sustainability/proven-process.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    meetJKImage: file(relativePath: { eq: "next-gen-branding/meet-jk.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    omniChannelImage: file(relativePath: { eq: "electrical-distributors/omnichannel.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    posts: allWpPost(filter: { id: { in: ["cG9zdDo0MTcx", "cG9zdDo1MDQy", "cG9zdDo1MDQ1"] } }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          date(formatString: "MMMM DD, YYYY")
          categories {
            nodes {
              name
              slug
            }
          }
          article {
            theme
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`
